<template>
  <VueMonacoEditor 
    :value="value"
    @change="$emit('input', $event)"
  />
</template>

<script>

import { loader } from '@guolao/vue-monaco-editor'
loader.config({
  paths: {
    vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.43.0/min/vs',
  },
})

// editor.vue
import { VueMonacoEditor } from '@guolao/vue-monaco-editor'
export default {
  components: { VueMonacoEditor },

  data () {
    return {
    }
  },
  props: {
    value: String,
  },
  mounted () {
    
  }
}

</script>

<style lang="scss" scoped>

</style>